import React, { Dispatch, SetStateAction } from 'react';
import { getPublicUrlFromS3Key } from 'services/global.service';

interface Props {
    background: string;
    hasBackgroundLoaded: boolean;
    setHasBackgroundLoaded: Dispatch<SetStateAction<boolean>>;
}

function AppBackground(props: Props): JSX.Element {
    const { background, hasBackgroundLoaded, setHasBackgroundLoaded } = props;

    if (background === '') {
        return <></>;
    }

    return (
        <div
            className={`App-background ${hasBackgroundLoaded && 'loaded'}`}
            style={{
                backgroundImage: `url(${getPublicUrlFromS3Key(background)})`,
            }}
        >
            <img
                alt="app-background"
                onLoad={(): void => {
                    setHasBackgroundLoaded(true);
                }}
                src={getPublicUrlFromS3Key(background)}
                style={{ display: 'none' }}
            />
        </div>
    );
}

export default AppBackground;
