import 'font-awesome/css/font-awesome.min.css';
import 'assets/css/Global.css';

import React, { useEffect, useState } from 'react';

import { Bundle } from '@storyslab/storyslab.common.models';
import Header from 'components/Header';
import Thumbnail from 'components/Thumbnail';

interface Props {
    bundle: Bundle | undefined;
    userEmail: string;
    logo: string;
    shortCode: string;
}

function Documents(props: Props): JSX.Element {
    const { bundle, logo, shortCode } = props;

    const [email, setEmail] = useState<string | undefined>('');
    const [username, setUsername] = useState('');

    useEffect(() => {
        setUsername(
            `${bundle?.userData.firstName} ${bundle?.userData.lastName}`,
        );
        setEmail(bundle?.userData.email);
    }, [bundle]);

    return (
        <>
            <Header email={email} logo={logo} username={username} />
            <div style={styles.contain}>
                {bundle?.enrichedData.map((file: any, index: number) => (
                    <Thumbnail
                        key={`file-${index}`}
                        file={file}
                        shortCode={shortCode}
                    />
                ))}
            </div>
        </>
    );
}

const styles: any = {
    contain: {
        alignSelf: 'flex-start',
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: '237px',
        maxWidth: '1210px',
    },
};

export default Documents;
