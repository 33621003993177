import './index.css';
import './App.css';

import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import App from './App';
import reportWebVitals from './reportWebVitals';

if (process.env.ENV_STAGE !== 'local') {
    Sentry.init({
        dsn: 'https://46ccc7b42312484083102a933e7a333b@o514778.ingest.sentry.io/5985382',
        environment: process.env.ENV_STAGE,
        integrations: [new Integrations.BrowserTracing()],
        release: 'storyslab.share.frontend@' + process.env.npm_package_version,
        tracesSampleRate: 0.1,
    });
}

ReactDOM.render(
    <React.StrictMode>
        <Sentry.ErrorBoundary fallback={<>An error has occurred.</>}>
            <App />
        </Sentry.ErrorBoundary>
    </React.StrictMode>,
    document.getElementById('root'),
);

reportWebVitals();
