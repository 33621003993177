import 'font-awesome/css/font-awesome.min.css';
import 'assets/css/Global.css';

import React, { useEffect, useState } from 'react';
import { faReply, faShare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getPublicUrlFromS3Key } from 'services/global.service';

interface Props {
    email: string | undefined;
    logo: string;
    username: string;
}

function Header(props: Props): JSX.Element {
    const { email, logo, username } = props;

    const [hasLoaded, setHasLoaded] = useState(false);

    useEffect(() => {
        setHasLoaded(true);
    }, []);

    return (
        <div style={styles.header}>
            <div
                style={styles.bar}
                className={`bar ${hasLoaded && 'loaded'}`}
            ></div>
            <img
                alt="logo"
                src={getPublicUrlFromS3Key(logo)}
                style={styles.logo}
                className={`logo ${hasLoaded && 'loaded'}`}
            />
            <div
                style={styles.emailTools}
                className={`email-tools ${hasLoaded && 'loaded'}`}
            >
                <div style={styles.sentBy}>sent by</div>
                <div style={styles.name}>{username}</div>
                <div style={styles.actions}>
                    <div
                        style={styles.action}
                        className="email-action"
                        onClick={(): void => {
                            window.open(
                                `mailto:${email}?subject=Files from StorySlab Share+`,
                            );
                        }}
                    >
                        <FontAwesomeIcon icon={faReply} style={styles.icon} />
                        reply
                    </div>
                    <div
                        style={styles.action}
                        className="email-action"
                        onClick={(): void => {
                            window.open(
                                `mailto:?subject=Subject&body=${window.location}`,
                            );
                        }}
                    >
                        <FontAwesomeIcon icon={faShare} style={styles.icon} />
                        forward
                    </div>
                </div>
            </div>
        </div>
    );
}

const styles: any = {
    action: {
        alignItems: 'center',
        display: 'flex',
        padding: '1rem 1rem 0',
    },
    actions: {
        alignItems: 'center',
        color: '#F6F7FE',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row' as const,
        fontWeight: 700,
    },
    bar: {
        backgroundColor: '#fff',
        boxShadow: '0 0 20px #666',
        position: 'absolute' as const,
        width: '100%',
        zIndex: -1,
    },
    emailTools: {
        backgroundColor: '#0065A3',
        boxShadow: '0 0 20px #666',
        height: '118px',
        padding: '1rem',
    },
    header: {
        display: 'flex',
        flexDirection: 'row' as const,
        justifyContent: 'center',
        left: 0,
        position: 'fixed' as const,
        top: 0,
        width: '100%',
    },
    icon: {
        fontSize: '28px',
        marginRight: '.5rem',
    },
    logo: {
        boxShadow: '0 0 20px #666',
        height: '160px',
        width: '160px',
        zIndex: 2,
    },
    name: {
        color: '#F6F7FE',
        fontSize: '21px',
        fontWeight: 700,
    },
    sentBy: {
        color: '#F6F7FE',
        fontSize: '15px',
        fontStyle: 'italic',
        textTransform: 'uppercase' as const,
    },
};

export default Header;
