import { ContentItem } from '@storyslab/storyslab.common.models';

interface EnrichedDataItem extends ContentItem {
    contentItemAsset: any;
}

export function getFileType(file: EnrichedDataItem): string {
    const { contentItemAsset, type } = file;

    if (type === 'link') {
        return 'link';
    }

    if (contentItemAsset === undefined) {
        return '';
    }

    if (typeof contentItemAsset === 'string') {
        let fileType: string | Array<string> = contentItemAsset.split('?');
        fileType = fileType[0].split('/');
        fileType = fileType[fileType.length - 1].split('.');
        fileType = fileType[fileType.length - 1];
        return fileType;
    }

    return 'unknown';
}

export function getShortCode(): string {
    let path: string | Array<string> = window.location.pathname;
    path = path.split('/');
    switch (path.length >= 2) {
        case true:
            return path[1];
        default:
            return '';
    }
}

export function getItemId(): string {
    let path: string | Array<string> = window.location.pathname;
    path = path.split('/');
    switch (path.length >= 3) {
        case true:
            return path[2];
        default:
            return '';
    }
}

export function getSubDomain(): string {
    const subDomainAsArray: Array<string> = window.location.host.split('.');

    const subdomain: string = subDomainAsArray[0];

    return subdomain;
}
