import '../App.css';

import React from 'react';
import StorySlabLogo from 'assets/images/logo.png';

function Loader(): JSX.Element {
    return (
        <header className="App-header">
            <img src={StorySlabLogo} className="App-logo" alt="logo" />
        </header>
    );
}

export default Loader;
