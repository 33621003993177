import React, {
    Dispatch,
    SetStateAction,
    useEffect,
    useRef,
    useState,
} from 'react';
import SimpleReactValidator from 'simple-react-validator';

interface Props {
    handleClick: (email: string) => void;
    setGlobalEmail: Dispatch<SetStateAction<string>>;
}

function GetStarted(props: Props): JSX.Element {
    const { handleClick, setGlobalEmail } = props;

    const [email, setEmail] = useState('');
    const [, setDidValidate] = useState(false);

    const validator: any = useRef(new SimpleReactValidator());

    useEffect(() => {
        setGlobalEmail(email);
    }, [email, setGlobalEmail]);

    return (
        <div className="single-card-contain">
            <div className="single-card-title">{`Let's get started`}</div>
            <div className="single-card-label">Please enter your email</div>
            <input
                style={styles.input}
                type="text"
                value={email}
                onChange={(e): void => {
                    setEmail(e.target.value);
                }}
            />
            {validator.current.message('email', email, 'required|email')}
            <div className="button-contain">
                <div
                    style={{ textDecoration: 'none' }}
                    onClick={(): void => {
                        if (validator.current.allValid()) {
                            handleClick(email);
                        } else {
                            validator.current.showMessages();
                            setDidValidate(true);
                        }
                    }}
                >
                    <div className="button">CONTINUE</div>
                </div>
            </div>
        </div>
    );
}

const styles: any = {
    input: {
        border: 'none',
        borderBottom: '1px solid #808080',
        fontSize: '18px',
        maxWidth: '563px',
        outline: 'none',
        width: '100%',
    },
};

export default GetStarted;
