import React from 'react';
import { Link } from 'react-router-dom';
import {
    faDownload,
    faExternalLinkAlt,
    faFile,
    faFileImage,
    faFilePdf,
    faFileVideo,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getFileType } from 'helpers';

import { ContentItem } from '@storyslab/storyslab.common.models';

interface EnrichedDataFile extends ContentItem {
    contentItemAsset: any;
}

interface Props {
    file: EnrichedDataFile;
    shortCode: string;
}

function AssetWrapper(props: any): JSX.Element {
    const styles: any = {
        contain: {
            alignItems: 'center',
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            padding: '.5rem',
        },
    };

    return <div style={styles.contain}>{props.children}</div>;
}

function GenericIcon(props: { file: any }): JSX.Element {
    switch (getFileType(props.file).toLowerCase()) {
        case 'link':
            return (
                <AssetWrapper>
                    <FontAwesomeIcon
                        icon={faExternalLinkAlt}
                        style={{ fontSize: '86px' }}
                    />
                </AssetWrapper>
            );
        case 'mp4':
        case 'm4v':
        case 'mov':
            return (
                <AssetWrapper>
                    <FontAwesomeIcon
                        icon={faFileVideo}
                        style={{ fontSize: '86px' }}
                    />
                </AssetWrapper>
            );
        case 'pdf':
            return (
                <AssetWrapper>
                    <FontAwesomeIcon
                        icon={faFilePdf}
                        style={{ fontSize: '86px' }}
                    />
                </AssetWrapper>
            );
        case 'jpg':
        case 'jpeg':
        case 'png':
            return (
                <AssetWrapper>
                    <FontAwesomeIcon
                        icon={faFileImage}
                        style={{ fontSize: '86px' }}
                    />
                </AssetWrapper>
            );
        default:
            return (
                <AssetWrapper>
                    <FontAwesomeIcon
                        icon={faFile}
                        style={{ fontSize: '86px' }}
                    />
                </AssetWrapper>
            );
    }
}

function RenderAsset(props: { file: any }): JSX.Element {
    const { file } = props;

    const styles: any = {
        maxHeight: '100%',
        maxWidth: '100%',
    };

    if (file.thumbnailAsset === null) {
        return <GenericIcon file={file} />;
    } else {
        return (
            <AssetWrapper>
                <img alt="thumbnail" src={file.thumbnailAsset} style={styles} />
            </AssetWrapper>
        );
    }
}

function Thumbnail(props: Props): JSX.Element {
    const { file, shortCode } = props;

    const linkSrc: any =
        file.type === 'link'
            ? {
                  pathname: file.target,
              }
            : {
                  pathname: `/${shortCode}/${file.id}`,
                  state: { file },
              };

    return (
        <>
            <Link
                to={linkSrc}
                style={{ color: '#000', textDecoration: 'none' }}
                target={file.type === 'link' ? '_blank' : '_self'}
            >
                <div style={styles.contain} className="thumbnail-contain">
                    <RenderAsset file={file} />
                    <div style={styles.meta}>
                        <div style={styles.metaText}>
                            <div style={styles.fileType}>
                                {getFileType(file)}
                            </div>
                            <div style={styles.name}>{file.name}</div>
                        </div>
                        <a
                            style={styles.download}
                            href={file.contentItemAsset}
                            target="_blank"
                            rel="noopener noreferrer"
                            download
                        >
                            <FontAwesomeIcon
                                icon={faDownload}
                                style={styles.icon}
                                className="download-icon"
                            />
                        </a>
                    </div>
                </div>
            </Link>
        </>
    );
}

const styles: any = {
    contain: {
        backgroundColor: '#fff',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column' as const,
        height: '306px',
        justifyContent: 'space-between',
        margin: '0 27px 27px',
        overflow: 'hidden',
        width: '247px',
    },
    download: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
    },
    fileType: {
        fontSize: '10px',
    },
    icon: {},
    meta: {
        backgroundColor: '#0065A3',
        color: '#F6F7FE',
        display: 'flex',
        padding: '.5rem .5rem 1rem 1rem',
    },
    metaText: {
        display: 'flex',
        flex: 3,
        flexDirection: 'column' as const,
        overflow: 'hidden',
    },
    name: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap' as const,
    },
};

export default Thumbnail;
