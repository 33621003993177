import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { useLocation } from 'react-router';
import { getFileType, getItemId } from 'helpers';
import PSPDFKit from 'pspdfkit';

import PreviewTitle from 'components/PreviewTitle';

const videoFormats: Array<string> = ['m4v', 'mp4', 'mov'];

interface Props {
    bundle: any;
}

interface ContentItem {
    [key: string]: any;
}

function loadDocument(file: ContentItem, myRef: any): void {
    const baseUrl: string = `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`;

    PSPDFKit.load({
        baseUrl,
        container: myRef.current,
        document: file.contentItemAsset,
        licenseKey: process.env.REACT_APP_PSPDFKIT_LICENSE,
    })
        .then((instance) => {
            // TBD
        })
        .catch((error) => {
            // TBD
        });
}

function Preview(props: Props): JSX.Element {
    const { bundle } = props;

    const myRef: MutableRefObject<null> = useRef(null);
    const data: any = useLocation();
    const [contentFile, setContentFile] = useState<ContentItem>();
    const [isVideo, setIsVideo] = useState(false);

    useEffect((): void => {
        let previewItem: any;

        // Direct access to URL (not from <Documents />), therefore no state passed in
        if (data.state === undefined) {
            // Because there's no state, find the correct item in bundle
            for (const contentItem of bundle.data.enrichedData) {
                if (contentItem.id === parseInt(getItemId())) {
                    previewItem = contentItem;
                    break;
                }
            }
            // Set preview state to video or other
            if (videoFormats.includes(getFileType(previewItem).toLowerCase())) {
                setIsVideo(true);
            } else {
                loadDocument(previewItem, myRef);
            }
            // Set item to local state for easy access
            setContentFile(previewItem);
        } else {
            previewItem = data.state.file;
            // Set preview state to video or other
            if (videoFormats.includes(getFileType(previewItem).toLowerCase())) {
                setIsVideo(true);
            } else {
                loadDocument(previewItem, myRef);
            }
            // Set item to local state for easy access
            setContentFile(previewItem);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {contentFile && <PreviewTitle contentFile={contentFile} />}
            <div ref={myRef} style={styles.container}>
                {isVideo && contentFile && (
                    <ReactPlayer
                        url={contentFile.contentItemAsset}
                        controls={true}
                        width="100%"
                        height="100%"
                        style={{ backgroundColor: '#000' }}
                    />
                )}
            </div>
        </>
    );
}

const styles: any = {
    container: {
        height: 'calc(100% - 50px)',
        left: 0,
        position: 'fixed',
        top: '50px',
        width: '100%',
    },
};

export default Preview;
