import { getShortCode, getSubDomain } from 'helpers';

export async function bootstrap(): Promise<any> {
    return fetch(
        (process.env.REACT_APP_PUBLIC_DETAILS_JSON as string)
            .replace('{{subdomain}}', getSubDomain())
            .replace('{{environment}}', process.env.ENV_STAGE as string),
    )
        .then((response) => response.json())
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(`Bootstrap failed`);
        });
}

export async function getBundle(
    applicationId: number,
    tenantId: number,
): Promise<any> {
    const shortCode: string = getShortCode();

    if (shortCode === '') {
        // No path in URL
        return { badBundle: true };
    }

    return fetch(
        `${process.env.REACT_APP_API_GATEWAY}/client/share/get-by-shortcode/${shortCode}`,
        {
            headers: {
                'storyslab-application-id': applicationId.toString(),
                'storyslab-tenant-id': tenantId.toString(),
            },
        },
    )
        .then((response) => {
            return response.json();
        })
        .then((response) => {
            return response;
        });
}
