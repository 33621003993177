import React from 'react';
import { Link } from 'react-router-dom';
import { faCaretLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getShortCode } from 'helpers';

interface Props {
    contentFile: ContentItem;
}

interface ContentItem {
    [key: string]: any;
}

function Title(props: Props): JSX.Element {
    const { contentFile } = props;

    return (
        <div style={styles.contain}>
            <Link to={`/${getShortCode()}`} style={{ textDecoration: 'none' }}>
                <div style={styles.back}>
                    <FontAwesomeIcon icon={faCaretLeft} style={styles.icon} />{' '}
                    Back to All Files
                </div>
            </Link>
            <div style={styles.title}>{contentFile.name}</div>
            <div style={styles.bump}></div>
        </div>
    );
}

const styles: any = {
    back: {
        alignItems: 'center',
        color: '#000',
        cursor: 'pointer',
        display: 'flex',
        fontSize: '14px',
        fontWeight: 700,
        padding: '1rem',
        textDecoration: 'none',
        width: '200px',
    },
    bump: {
        width: '200px',
    },
    contain: {
        alignItems: 'center',
        backgroundColor: '#fff',
        display: 'flex',
        height: '50px',
        justifyContent: 'space-between',
        position: 'fixed',
        top: 0,
        width: '100%',
    },
    icon: {
        fontSize: '24px',
        marginRight: '.5rem',
    },
    title: {
        fontSize: '18px',
        fontWeight: 600,
    },
};

export default Title;
