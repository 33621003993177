import React from 'react';

interface Props {
    bodyText: string;
    titleText: string;
}

function MessageTemplate(props: Props): JSX.Element {
    const { bodyText, titleText } = props;

    return (
        <div className="single-card-contain">
            <div className="single-card-title">{titleText}</div>
            <div className="single-card-label">{bodyText}</div>
            <div className="button-contain">
                <div style={{ textDecoration: 'none' }}>
                    <div className="button">CONTACT SALES REP</div>
                </div>
            </div>
        </div>
    );
}

export default MessageTemplate;
