/* eslint-disable multiline-comment-style */
/* eslint-disable max-lines-per-function */
import './App.css';

import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { getShortCode } from 'helpers';
import { bootstrap, getBundle } from 'services/bootstrap.service';
import Cookies from 'universal-cookie';

import { Bundle } from '@storyslab/storyslab.common.models';
import AppBackground from 'components/AppBackground';
import Documents from 'components/Documents';
import GetStarted from 'components/GetStarted';
import Loader from 'components/Loader';
import MessageTemplate from 'components/MessageTemplate';
import Preview from 'components/Preview';

interface MyBundle extends Bundle {
    challenges?: any;
}

function App(): JSX.Element {
    const [background, setBackground] = useState('');
    const [bundle, setBundle] = useState<MyBundle>();
    const [bundleResponse, setBundleResponse] = useState<any>();
    const [email, setEmail] = useState('');
    const [hasBackgroundLoaded, setHasBackgroundLoaded] = useState(false);
    const [isIdentityCaptured, setIsIdentityCaptured] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [logo, setLogo] = useState('');
    const [messageBodyText, setMessageBodyText] = useState('');
    const [messageTitleText, setMessageTitleText] = useState('');
    const [shortCode, setShortCode] = useState('');
    const [shouldShowMessage, setShouldShowMessage] = useState(false);

    const cookies: Cookies = new Cookies();

    useEffect(() => {
        bootstrap()
            .then((response) => {
                const { applicationId, tenantId } = response;
                setBackground(
                    response.appMeta.authenticate.style.backgroundImage,
                );
                setLogo(response.appMeta.authenticate.style.squareLogo);
                getBundle(applicationId, tenantId).then((response) => {
                    // Error handling in bundleResponse useEffect
                    setBundleResponse(response);
                });
            })
            .catch((error) => {
                setMessageBodyText(
                    `Please try again later or contact your rep for help`,
                );
                setMessageTitleText(`Nothing to see here...`);
                setShouldShowMessage(true);
            });
        setShortCode(getShortCode());
    }, []);

    useEffect((): void => {
        if (bundleResponse === undefined) {
            return;
        }

        if ('badBundle' in bundleResponse) {
            setMessageBodyText(
                `Please try again later or contact your rep for help`,
            );
            setMessageTitleText(`Nothing to see here...`);
            setShouldShowMessage(true);
            setIsLoading(false);
            return;
        }

        if ('error' in bundleResponse) {
            if (
                (bundleResponse as any).error.message ===
                'Access to this bundle has been revoked'
            ) {
                setMessageBodyText(
                    `Looks like the content you're trying to access is no longer available.`,
                );
                setMessageTitleText(`We're sorry!`);
                setShouldShowMessage(true);
            }
            if (
                (bundleResponse as any).error.message ===
                'Unable to find bundle'
            ) {
                setMessageBodyText(
                    `Please try again later or contact your rep for help`,
                );
                setMessageTitleText(`Nothing to see here...`);
                setShouldShowMessage(true);
            }
            if (
                (bundleResponse as any).error.message ===
                'Access to this bundle has expired'
            ) {
                setMessageBodyText(`Contact your rep for help`);
                setMessageTitleText(`Bundle expired`);
                setShouldShowMessage(true);
            }
            setIsLoading(false);
            return;
        }

        setBundle(bundleResponse.data);

        setIsLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bundleResponse]);

    const saveEmail: (email: string) => void = () => {
        cookies.set('userEmail', email);
        setIsIdentityCaptured(!isIdentityCaptured);
    };

    function renderFileOrFiles(): any {
        return (
            <BrowserRouter>
                <Switch>
                    <Route path={`/${shortCode}/:id`}>
                        <Preview bundle={bundle} />
                    </Route>
                    <Route path="/">
                        <Documents
                            logo={logo}
                            bundle={bundle}
                            shortCode={shortCode}
                            userEmail={email}
                        />
                    </Route>
                </Switch>
            </BrowserRouter>
        );
    }

    function renderContent(): JSX.Element {
        if (isLoading) {
            return <Loader />;
        }

        if (shouldShowMessage) {
            return (
                <MessageTemplate
                    bodyText={messageBodyText}
                    titleText={messageTitleText}
                />
            );
        }

        if (bundle?.challenges) {
            if (bundle?.challenges?.collectIdentity === true) {
                if (cookies.get('userEmail') === undefined) {
                    return (
                        <GetStarted
                            handleClick={saveEmail}
                            setGlobalEmail={setEmail}
                        />
                    );
                }
            }
            if (bundle?.challenges?.password === true) {
                return <>password protected</>;
            }
        }

        return renderFileOrFiles();
    }

    return (
        <div className="App">
            <AppBackground
                background={background}
                hasBackgroundLoaded={hasBackgroundLoaded}
                setHasBackgroundLoaded={setHasBackgroundLoaded}
            />
            {renderContent()}
        </div>
    );
}

export default App;
